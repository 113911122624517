.login-sign-up-heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: $color101828;
    margin: 0;
}

.login-sign-up-sub-heading {
    line-height: 24px;
    text-align: center;
    color: $color667085;
    margin-top: 4px;
    margin-bottom: 32px;
}

.login-container {
    max-width: 360px;
    margin: auto;
    box-sizing: border-box;
    margin-top: 80px;
    margin-bottom: 80px;

    .input-field-with-icon-label-wrapper,
    .async-autocomplete-wrapper {
        margin-top: 20px;
    }

    .save-btn,
    .cancel-btn {
        width: 100%;
    }

    .otp-input-container {
        & > div {
            display: flex;
            gap: 24px;

            input {
                width: 67px !important;
                height: 90px !important;
                font-weight: 500;
                font-size: 72px;
                letter-spacing: -0.02em;
                color: $color7F56D9;
                background: transparent;
                border: 0;
                border-bottom: 4px solid $color7F56D9;
                border-radius: 0 !important;
                box-sizing: border-box;
                caret-color: $color7F56D9;

                @media (max-width: 360px) {
                    width: 50px !important;
                    height: 80px !important;
                    font-size: 60px;
                }
            }

            @media (max-width: 360px) {
                gap: 15px;
            }
        }
    }

    .skip-link {
        display: flex;
        font-size: 14px;
        line-height: 20px;
        color: $color667085;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        svg {
            width: 18px;
            height: 18px;
        }

        &:hover {
            text-decoration: none;
            color: $color475467;
            cursor: pointer;
        }
    }

    .back-btn {
        display: flex;
        font-size: 14px;
        line-height: 20px;
        color: $color6941C6;
        text-align: center;
        align-items: center;
        margin-bottom: 16px;

        svg {
            width: 18px;
            height: 18px;
        }

        &:hover {
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.login-sign-up-link {
    font-size: 14px;
    line-height: 20px;
    color: $color667085;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 0;

    a,
    .span-link {
        font-weight: 500;
        color: $color6941C6;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}


.agreed-to-terms-of-service {
    font-size: 14px;
    color: $color101828;

    a {
        color: $color6941C6;
    }
}