.sign-up-container {
    display: flex;
    box-sizing: border-box;

    .content-wrapper {
        width: 45%;
        height: 100vh;
        background: $colorFFFFFF;
        padding: 32px;
        box-sizing: border-box;
        overflow-y: auto;

        .form-content-wrapper {
            max-width: 360px;
            margin: auto;
            margin-top: 40px;

            .heading {
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;
                letter-spacing: -0.02em;
                color: $color101828;
                margin: 0;
            }

            .sub-heading {
                line-height: 24px;
                color: $color667085;
                margin-top: 12px;
                margin-bottom: 32px;
            }

            .input-field-with-icon-label-wrapper,
            .phone-wrapper {
                margin-top: 20px;
            }

            button {
                width: 100%;
            }
        }
    }

    .details-wrapper {
        position: relative;
        width: 55%;
        height: 100vh;
        background: $colorF9F5FF;
        padding: 96px 64px 0 80px;
        box-sizing: border-box;
        overflow: hidden;

        .image-container {
            position: absolute;
            height: -webkit-fill-available;
            width: -webkit-fill-available;
            background: url("../../assets/images/sign-up-image.png");
            background-repeat: no-repeat;
            background-size: cover;
            border-top: 5px solid;
            border-left: 5px solid;
            box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
            border-top-left-radius: 12px;
        }

        @media (min-width: 993px) and (max-width: 1440px) {
            padding: 96px 35px 0 45px;

            .testimonial-wrapper {
                .testimonial-desc {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .content-wrapper {
            width: 100%;
            height: unset;
            min-height: 100vh;
            overflow-y: unset;
        }

        .details-wrapper {
            display: none;
        }
    }
}
