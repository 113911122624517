.common-btn {
    min-height: 44px;
    width: fit-content;
    padding: 5px 16px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    white-space: pre-wrap !important;

    .MuiButton-startIcon, .MuiButton-endIcon {
        svg {
            font-size: 18px;
            width: 18px;
            height: 18px;
        }
    }
}

.cancel-btn {
    background: $colorFFFFFF !important;
    color: $color344054 !important;
    border: 1px solid $colorD0D5DD !important;

    &:hover {
        background: $colorF9FAFB !important;
    }
}

.save-btn {
    background: $color7F56D9 !important;
    color: $colorFFFFFF !important;

    &:hover {
        background: $color7448d4 !important;
    }
}

.delete-btn {
    background: $colorF94444 !important;
    color: $colorFFFFFF !important;

    &:hover {
        background: $colorD63333 !important;
    }
}

.save-btn.btn-disabled {
    background: $colorE9D7FE !important;
}

.add-another-btn {
    display: flex;
    align-items: center;
    width: fit-content;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color6941C6;
    gap: 8px;
    cursor: pointer;
    margin-top: 2px;
    margin-bottom: 0;
}

.icon-comp.row-item-delete-btn {
    display: none;
    position: absolute;
    top: -7px;
    left: -5px;
    z-index: 1;
    padding: 5px;
    background: $colorF4EBFF;
    border-radius: 50%;
    cursor: pointer;
    width: 16px;
    height: 16px;

    svg {
        width: 16px;
        height: 16px;
    }
}

.project-schedule-add-another-btn {
    margin-top: 10px;
}